import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_el_tabs = _resolveComponent("el-tabs");
  return _openBlock(), _createBlock(_component_el_tabs, {
    modelValue: _ctx.editableTabsValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.editableTabsValue = $event),
    type: "card",
    class: "demo-tabs",
    closable: "",
    onTabRemove: _ctx.removeTab,
    onTabClick: _ctx.clickTab
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editableTabs, item => {
      return _openBlock(), _createBlock(_component_el_tab_pane, {
        key: item.name,
        label: item.title,
        name: item.name
      }, null, 8, ["label", "name"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "onTabRemove", "onTabClick"]);
}