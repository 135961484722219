import { defineComponent, computed, reactive, onMounted, toRefs } from "vue";
import { useRouter } from "vue-router";
import store from "../store";
import store1 from "../store/tabls";
export default defineComponent({
  setup() {
    const selectMenu = item => {
      store1.commit("addTab", item);
    };
    const router = useRouter();
    const state = reactive({
      activeMenuItemIndex: ""
    });
    function updateAsideMenu() {
      state.activeMenuItemIndex = router.currentRoute.value.path;
    }
    router.afterEach((to, from) => {
      updateAsideMenu();
    });
    onMounted(() => {
      updateAsideMenu();
    });
    const menuList = computed(() => {
      return store.state.menuList;
    });
    const index = computed(() => {
      return store1.state.editableTabsValue;
    });
    return {
      selectMenu,
      menuList,
      index,
      ...toRefs(state)
    };
  }
});