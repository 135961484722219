import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, toRefs, ref } from "vue";
import { LoginData1 } from "../type/ll";
import { ElMessage } from "element-plus";
import { login } from "../request/api";
import { useRouter } from "vue-router";
import store from "../store";
export default defineComponent({
  setup() {
    const data = reactive(new LoginData1());
    const router = useRouter(); //-->$router
    const rules = {
      userid: [{
        required: true,
        message: "Please input username",
        trigger: "blur"
      }, {
        min: 1,
        max: 10,
        message: "Length should be 3 to 10",
        trigger: "blur"
      }],
      userpw: [{
        required: true,
        message: "Please input password",
        trigger: "blur"
      }, {
        min: 1,
        max: 10,
        message: "Length should be 3 to 10",
        trigger: "blur"
      }]
    };
    const ruleFormRef = ref();
    //const store = useStore();
    const submitForm = formEl => {
      if (!formEl) return;
      formEl.validate(valid => {
        if (valid) {
          localStorage.clear();
          login(data.ruleForm).then(res => {
            if (res.data.code == 401) ElMessage(res.data.msg);
            // 将token进行保存
            localStorage.setItem("token", res.data.data.token);
            if (res.data.data.hfaceurl == "" || res.data.data.hfaceurl == null) store.commit("sethfaceurl", "");else store.commit("sethfaceurl", store.state.imageurl + "/image/" + res.data.data.hfaceurl);
            store.commit("setName", res.data.msg);
            store.commit("setowner", data.ruleForm.userid);
            store.commit("setusertype", "admin");
            // 跳转页面，首页
            //router.push('/' + res.data.msg)
            router.push("/home");
          }).catch(err => {});
        } else {
          return false;
        }
      });
    };
    const resetForm = () => {
      data.ruleForm.userid = "";
      data.ruleForm.userpw = "";
    };
    return {
      ...toRefs(data),
      rules,
      ruleFormRef,
      submitForm,
      resetForm
    };
  }
});