import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import { logout } from "../request/api";
import { useRouter, useRoute } from 'vue-router';
import SideMenu from '../inc/SideMenu.vue';
import Tabls from '../inc/Tabs.vue';
import Tabls1 from '../inc/tabls1.vue';
import store from "../store/tabls";
export default defineComponent({
  name: "HomeView",
  components: {
    SideMenu,
    Tabls,
    Tabls1
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const list = router.getRoutes().filter(v => v.meta.isShow);
    const delToken = () => {
      logout().then(() => {});
      localStorage.clear();
      router.push('/login');
      store.commit("resetState");
    };
    return {
      list,
      active: route.path,
      delToken
    };
  }
});