import "core-js/modules/es.array.push.js";
import { ref, defineComponent } from 'vue';
import store from "../store/tabls";
import { useRouter } from "vue-router";
export default defineComponent({
  setup() {
    const router = useRouter();
    const editableTabsValue = ref(store.state.editableTabsValue);
    let editableTabs = ref(store.state.editableTabs);
    // let  editableTabs = computed(()=>{return store.state. editableTabs})
    const removeTab = targetName => {
      const tabs = editableTabs.value;
      let activeName = editableTabsValue.value;
      if (activeName === 'Index') {
        return;
      }
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      editableTabsValue.value = activeName;
      store.commit("removeTab", targetName);
      editableTabs.value = tabs.filter(tab => tab.name !== targetName);
      // editableTabs = ref(store.state.editableTabs)
      router.push(activeName);
    };
    const clickTab = targetName => {
      router.push(targetName.props.name);
    };
    return {
      editableTabsValue,
      editableTabs,
      removeTab,
      clickTab
    };
  }
});