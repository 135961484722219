import service from ".";
export function upusertype(fid: String, type: String) {
  return service({
    url: "/userlist/upusertype/" + fid + "/" + type,
    method: "get",
  });
}

export function getuserpage(data: any) {
  return service({
    url: "/userlist/getuserpage",
    method: "post",
    data: data,
  });
}

export function getauthuserpage(data: any) {
  return service({
    url: "/userlist/getauthuserpage",
    method: "post",
    data: data,
  });
}

export function getpersoninfo(userid: String) {
  return service({
    url: "/userlist/getPinfo/" + userid,
    method: "get",
    
  });
}
export function saveupauthuser(data: any) {
  return service({
    url: "/userlist/saveup",
    method: "post",
    data,
   
  });
}
//---------------web vip-----------------------
export function sendnewpassword(emailname: String) {
  return service({
    url: "/userlist/sendnewpassword/" + emailname,
    method: "get",
    headers: {
      type: "user",
    },
  });
}
export function weblogin(data: any) {
  return service({
    url: "/userlist/login",
    method: "post",
    data,
    headers: {
      type: "user",
    },
  });
}
export function getvisitornum() {
  return service({
    url: "/userlist/getvisitornum",
    method: "get",
    headers: {
      type: "user",
    },
  });
}
export function userregister(data: any) {
  return service({
    url: "/userlist/register",
    method: "post",
    data,
    headers: {
      type: "user",
    },
  });
}
export function weblogout() {
  return service({
    url: "/userlist/logout",
    method: "get",
    headers: {
      type: "user",
    },
  });
}
export function getpeopleinfo(userid: String) {
  return service({
    url: "/userlist/getPinfo/" + userid,
    method: "get",
    headers: {
      type: "user",
    },
  });
}
export function upuserattri(type:Number,userid: String) {
  return service({
    url: "/userlist/upuserattri/" + type+"/"+userid,
    method: "get",
    headers: {
      type: "user",
    },
  });
}
export function webuppassword(userpw: String, opass: String) {
  return service({
    url: "/userlist/uppassword/" + userpw + "/" + opass,
    method: "get",
    headers: {
      type: "user",
    },
  });
}
export function vipgetmystudent(data: any) {
  return service({
    url: "/userlist/getmystudent",
    method: "post",
    data,
    headers: {
      type: "user",
    },
  });
}

export function vipgetmystudentt(data: any) {
  return service({
    url: "/userlist/getmystudentt",
    method: "post",
    data,
    headers: {
      type: "user",
    },
  });
}

export function vipgetstudent(userid: String,ifagree:Number,type:String) {
  return service({
    url: "/userlist/getstudent/"+userid+"/"+ifagree+"/"+type,
    method: "get",
   
    headers: {
      type: "user",
    },
  });
}
export function saveupuser(data: any) {
  return service({
    url: "/userlist/saveup",
    method: "post",
    data,
    headers: {
      type: "user",
    },
  });
}
export function getnauuser(data: any) {
  return service({
    url: "/userlist/getnauuser",
    method: "post",
    data,
    headers: {
      type: "user",
    },
  });
}
export function getfreeuser(data: any) {
  return service({
    url: "/userlist/getfreeuser",
    method: "post",
    data,
    headers: {
      type: "user",
    },
  });
}
export function getmyuser(data: any) {
  return service({
    url: "/userlist/getmyuser",
    method: "post",
    data,
    headers: {
      type: "user",
    },
  });
}
export function getuserbyassociationid(associationid: String, name: String) {
  return service({
    url: "/userlist/getuserbyassociationid" + "/" + associationid + "/" + name,
    method: "get",

    headers: {
      type: "user",
    },
  });
}
export function getparentid(childrenid: String, type: String) {
  return service({
    url: "/userlist/getparentid" + "/" + childrenid + "/" + type,
    method: "get",

    headers: {
      type: "user",
    },
  });
}
export function getuserbyname(name: String) {
  return service({
    url: "/userlist/getuserbyname" + "/" + name ,
    method: "get",

    headers: {
      type: "user",
    },
  });
}
export function ifingroup(dzid: String, userid: String) {
  return service({
    url: "/userlist/getparentid" + "/" + dzid + "/" + userid,
    method: "get",

    headers: {
      type: "user",
    },
  });
}
export function upvipstatus(userid: String, ifvip: String,type:string) {
  return service({
    url: "/userlist/upvipstatus" + "/" + userid + "/" + ifvip+"/"+type,
    method: "get",

   
  });
}
export function vipgetuserpage(data: any) {
  return service({
    url: "/userlist/getvipuserpage",
    method: "post",
    data: data,
    headers: {
      type: "user",
    },
  });
}
