import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-46fc2858"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "login-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
    ref: "ruleFormRef",
    model: _ctx.ruleForm,
    "status-icon": "",
    rules: _ctx.rules,
    "label-width": "120px",
    class: "demo-ruleForm"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "Acconut",
      prop: "userid"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.ruleForm.userid,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.ruleForm.userid = $event),
        autocomplete: "off"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "password",
      prop: "password"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.ruleForm.userpw,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.ruleForm.userpw = $event),
        type: "userpw",
        autocomplete: "off"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        type: "primary",
        onClick: _cache[2] || (_cache[2] = $event => _ctx.submitForm(_ctx.ruleFormRef))
      }, {
        default: _withCtx(() => [_createTextVNode(" Submit ")]),
        _: 1
      }), _createVNode(_component_el_button, {
        onClick: _ctx.resetForm
      }, {
        default: _withCtx(() => [_createTextVNode("Reset")]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"])]);
}