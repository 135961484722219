import service from ".";
interface LoginData1 {
    userid: string,
    userpw: string
}
interface porgData {

    fid: string
}


export function saveupsdir(data: any) {
    return service({
        url: "/sdir/saveup",
        method: "post",
        data
    })
}

export function getmaster() {
    return service({
        url: "/sdir/master",
        method: "get"
    })
}
export function getsdirbymaster(mastername: string) {
    return service({
        url: "/sdir/psdir/"+mastername,

        method: 'get',
       
    })
}

export function mgetsdirbymaster(mastername: string) {
    return service({
        url: "/sdir/mpsdir/"+mastername,

        method: 'get',
       
    })
}
export function getwebheader(mastername: string) {
    return service({
        url: "/sdir/psdir/"+mastername,

        method: 'get',
        headers: {
            "type":"user",
          },
       
    })
}



export function deletesdirbymastername(data:any) {
    return service({
        url: "/sdir/delete",
        method: "post",
        data

    })
}


export function up(data:any) {
    return service({
        url: "/sdir/up",
        method: "post",
        data
    })
}

export function down(data:any) {
    return service({
        url: "/sdir/down",
        method: "post",
        data
       
    })
}
//-------------------------------------------------------
export function webgetsdirbymaster(mastername: any) {
    return service({
        url: "/sdir/psdir/"+mastername,

        method: 'get',
        headers: {
            "type":"user",
          },
    })
}
//----------------------------------------------------------
export function progsaveupsdir(data: any) {
    return service({
        url: "/progdictionary/saveup",
        method: "post",
        data,
        headers: {
            "type":"user",
          },
    })
}

export function proggetmaster() {
    return service({
        url: "/progdictionary/master",
        method: "get",
        headers: {
            "type":"user",
          },
    })
}
export function proggetsdirbymaster(mastername: string) {
    return service({
        url: "/progdictionary/pProgdictionary/"+mastername,

        method: 'get',
        headers: {
            "type":"user",
          },
    })
}

export function progmgetsdirbymaster(mastername: string) {
    return service({
        url: "/progdictionary/mpProgdictionary/"+mastername,

        method: 'get',
        headers: {
            "type":"user",
          },
    })
}
export function proggetwebheader(mastername: string) {
    return service({
        url: "/progdictionary/pProgdictionary/"+mastername,

        method: 'get',
        headers: {
            "type":"user",
          },
       
    })
}



export function progdeletesdirbymastername(data:any) {
    return service({
        url: "/progdictionary/delete",
        method: "post",
        data,
        headers: {
            "type":"user",
          },

    })
}


export function progup(data:any) {
    return service({
        url: "/progdictionary/up",
        method: "post",
        data,
        headers: {
            "type":"user",
          },
    })
}

export function progdown(data:any) {
    return service({
        url: "/progdictionary/down",
        method: "post",
        data,
        headers: {
            "type":"user",
          },
       
    })
}