
import { TreeKey } from "element-plus/es/components/tree/src/tree.type";
import service from ".";
interface LoginData1 {
    userid: string,
    userpw: string
}
interface pmenuData {

    fid: string
}
// 登录接口
export function login(data: LoginData1) {
    return service({
        url: "/useradm/login",
        method: "post",
        data,
        
    })
}

export function getalluseradm() {
    return service({
        url: "/useradm/useradm",
        method: "get",
        
    })
}
export function logout() {
    return service({
        url: "/useradm/logout",
        method: "get",
        
    })
}
export function getallgroupid() {
    return service({
        url: "/useradmgroup/groupid",
        method: "get",
        
    })
}

export function saveupuseradm(data:any) {
    return service({
        url: "/useradm/saveupdate",
        method: "post",
        data
        
    })
}
export function saveupuseradmgroup(data:any) {
    return service({
        url: "/useradmgroup/saveupdate",
        method: "post",
        data
        
    })
}
export function uppassword(userpw:String,opass:String) {
    return service({
        url: "/useradm/uppassword/"+userpw+"/"+opass,
        method: "get",
        
        
    })
}
export function deleteuseradmgroup(id:string) {
    return service({
        url: "/useradmgroup/delete/"+id,
        method: "get",
        
    })
}
export function savepower(data:TreeKey[],groupid:String) {
    return service({
        url: "/groupmenu/savepower/"+data+"/"+groupid,
        method: "get",
     
        
    })
}

export function getcheckedmenu(groupid:string) {
    return service({
        url: "/groupmenu/getcheckedmenu/"+groupid,
        method: "get",
     
        
    })
}
export function deleteuseradm(userid:string) {
    return service({
        url: "/useradm/delete/"+userid,
        method: "get",
        
    })
}
export function searchuseradm(name:string) {
    return service({
        url: "/useradm/search/"+name,
        method: "get",
        
    })
}

export function upsavemenu(data: any) {
    return service({
       // url: "/infomenu/" + (b ? 'update' : 'save'),
       url: "/infomenu/saveupdate",
        method: "post",
        data
    })
}

export function getmenulist() {
    return service({
        url: "/infomenu/list",
        method: "get"
    })
}
export function getmenubyid(id: Number) {
    return service({
        url: "/infomenu/pmenu/" + id,

        method: 'get',
       
    })
}

export function deletemenubyid(id:any) {
    return service({
        url: "/infomenu/delete/" + id,
        method: "get"

    })
}
export function up(data:any) {
    return service({
        url: "/infomenu/up",
        method: "post",
        data
    })
}

export function down(data:any) {
    return service({
        url: "/infomenu/down",
        method: "post",
        data
       
    })
}
