import { createStore, Store } from "vuex";
import { ComponentCustomProperties } from "vue";
declare module "@vue/runtime-core" {
  interface Stata {
    name: "";
    menuList: any[];
    hasRoutes: false;
    editableTabsValue: 'Index',
    editableTabs: [{
        title: string,
        name: string,
    }]
    item: any;
    owner:""
    vip:false
    amenu:""
    uuid:""
  }
  interface ComponentCustomProperties {
    $store: Store<Stata>;
  }
}

declare module "@vue/runtime-core" {
  interface Stata1 {
    name: "";
    menuList: any[];
    hasRoutes: false;
    editableTabsValue: 'Index',
    editableTabs: [{
        routerlink: string,
        dataname: string,
    }]
    item: any;
    owner:""
    vip:false
    hfaceurl:""
    imageurl:""
    uuid:""
  }
  interface ComponentCustomProperties {
    $store: Store<Stata>;
  }
}
export default createStore({
  state: {
    name: "",
    menuList: [],
    rangeList: [],
    item: "",
    hasRoutes: false,
    owner:"",
    vip:false,
    type:"",
    powerList: [],
    usertype:"",
    hfaceurl:"",
    imageurl:"",
    baseurl:"",
    isConnected: false,
    amenu:"",
    mmenu:"",
    uuid:""
  },
  
  mutations: {
    setConnected(state: any, isConnected: boolean) {
      state.isConnected = isConnected
    },
    settype(stata: any, type: string) {
      stata.type = type;
    },
    setimageurl(stata: any, imageurl: string) {
      stata.imageurl = imageurl;
    },
    sethfaceurl(stata: any, hfaceurl: string) {
      stata.hfaceurl =hfaceurl;
    },
    setamenu(stata: any, amenu: string) {
      stata.amenu =amenu;
    },
    setmmenu(stata: any, mmenu: string) {
      stata.mmenu =mmenu;
    },
    setusertype(stata: any, usertype: string) {
      stata.usertype = usertype;
    },
    setName(stata: any, name: string) {
      stata.name = name;
    },
    setvip(stata: any, vip: boolean) {
      stata.vip = vip;
    },
    setjf(stata: any, jf:Number) {
      stata.jf = jf;
    },
    setMenuList(state: any, menulist: any[]) {
      state.menuList = menulist;
    },
    setpowerList(state: any, powerlist: any[]) {
      state.powerList = powerlist;
    },
    setrangeList(state: any, rangelist: any[]) {
      state.rangeList = rangelist;
    },
    setowner(stata: any, owner: string) {
      stata.owner = owner;
    },
    setuuid(stata: any, uuid: string) {
      stata.uuid = uuid;
    },
    resetStore(stata:any){
      stata.name="";
      stata.owner="";
      stata.vip=false;
      stata.jf=0;
      stata.type=""
      stata.usertype=""
      stata.amenu=""
      stata.uuid=""
      
    },

   
    
    changeRouteStatus(state, hasRoutes) {
      state.hasRoutes = hasRoutes;
    },
  },
  actions: {
    connect({ commit }: { commit: any }) {
      // 连接成功后，将 isConnected 状态设置为 true
      commit('setConnected', true)
    },
    disconnect({ commit }: { commit: any }) {
      // 断开连接或退出登录时，将 isConnected 状态设置为 false
      commit('setConnected', false)
    }
  },
  
});
