import { createStore, Store } from "vuex";
import { ComponentCustomProperties } from "vue";
declare module "@vue/runtime-core" {
  interface Stata {
    editableTabsValue: "Index";
    editableTabs: [
      {
        title: string;
        name: string;
      }
    ];
    item: any;
  }
  interface ComponentCustomProperties {
    $store: Store<Stata>;
  }
}
export default createStore({
  state: {
    item: "",

    editableTabsValue: "Index",
    editableTabs: [
      {
        title: "首页",
        name: "",
      },
    ],
  },
  mutations: {
    addTab(state, tab) {
      let index = state.editableTabs.findIndex(
        (e: { name: any }) => e.name === tab.path
      );

      if (index === -1) {
        state.editableTabs.push({
          title: tab.title,
          name: tab.path,
        });
      }

      state.editableTabsValue = tab.path;
    },
    removeTab(state, ttab: string) {
      state.editableTabs = state.editableTabs.filter(
        (tab) => tab.name !== ttab
      );
    },
    resetState: (state) => {
      state.editableTabsValue = "Index";
      state.editableTabs = [
        {
          title: "首页",
          name: "Index",
        },
      ];
    },
  },
});
