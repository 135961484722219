import "core-js/modules/es.array.push.js";
import { defineComponent, onMounted, reactive, ref } from "vue";
import store from "@/store";
import { useRouter, useRoute } from "vue-router";
import vipmenu from "@/inc/vipmenu.vue";
import viptabs from "@/inc/viptabs.vue";
import { getpviptype } from "@/request/vipmenu";
export default defineComponent({
  name: "HomeView",
  components: {
    vipmenu,
    viptabs
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const ttype = ref("");
    const data = reactive({
      viptype: []
    });
    onMounted(() => {
      getpviptype(store.state.owner).then(res => {
        data.viptype = res.data.data;
        ttype.value = data.viptype[0].type;
      });
    });
    const list = router.getRoutes().filter(v => v.meta.isShow);
    const gotoweb = () => {
      router.push('/webpage/homepage');
    };
    return {
      list,
      active: route.path,
      gotoweb,
      viptabs,
      ttype,
      data
    };
  }
});