import service from ".";
interface LoginData1 {
    userid: string,
    userpw: string
}
interface porgData {

    fid: string
}


export function saveupdatevipmenu(data: any) {
    return service({
        url: "/vipmenu/saveupdate",
        method: "post",
        data
    })
}

export function saveupdatevipuser(data: any) {
    return service({
        url: "/vipuser/saveupdate",
        method: "post",
        data
    })
}

export function getmaster() {
    return service({
        url: "/vipmenu/master",
        method: "get"
    })
}
export function getvipmenubymaster(mastername: string) {
    return service({
        url: "/vipmenu/pvipmenu/"+mastername,

        method: 'get',
       
    })
}

export function getvipuser(userid: string) {
    return service({
        url: "/vipuser/vipuser/"+userid,

        method: 'get',
       
    })
}
export function getvipusera(userid: string,type:String) {
    return service({
        url: "/vipuser/vipuser/"+userid+"/"+type,

        method: 'get',
      
    })
}
export function deletevipmenubymastername(data:any) {
    return service({
        url: "/vipmenu/delete",
        method: "post",
        data

    })
}


export function up(data:any) {
    return service({
        url: "/vipmenu/up",
        method: "post",
        data
    })
}

export function down(data:any) {
    return service({
        url: "/vipmenu/down",
        method: "post",
        data
       
    })
}
export function deletevip(userid:String,type:String) {
    return service({
        url: "/vipuser/deletevip/"+userid+"/"+type,

        method: 'get',
       
    })
}
//------------------------------------vip-----------------------------------------
export function deleteauth(id:String) {
    return service({
        url: "/vipuser/deleteauth/"+id,

        method: 'get',
        headers: {
            "type":"user",
          },
    })
}

export function getvipuserv(userid: string,type:String) {
    return service({
        url: "/vipuser/vipuser/"+userid+"/"+type,

        method: 'get',
        headers: {
            "type":"user",
          },
    })
}

export function getpviptype(userid: string) {
    return service({
        url: "/vipuser/vipuser/"+userid,

        method: 'get',
        headers: {
            "type":"user",
          },
    })
}
export function getvipmenubymasterv(mastername: string) {
    return service({
        url: "/vipmenu/pvipmenu/"+mastername,

        method: 'get',
        headers: {
            "type":"user",
          },
    })
}
export function getmyauuser(data: any) {
    return service({
        url: "/vipuser/getmyauuser",
        method: "post",
        data,
        headers: {
            "type":"user",
          },
    })
}
export function saveupvipuser(data: any) {
    return service({
        url: "/vipuser/saveupvip",
        method: "post",
        data,
        headers: {
            "type":"user",
          },
    })
}


export function getpowerrouter(powername:String,type:String) {
    return service({
        url: "/vipmenu/getpowerrouter/"+powername+"/"+type,

        method: 'get',
        headers: {
            "type":"user",
          },
    })
}