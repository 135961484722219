import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import ElementPlus from "element-plus";
//import "element-plus/dist/index.css";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "lib-flexible/flexible.js";
//import Vue3Websocket from 'vue3-websocket'
// 引入iconfont图标
import "../src/assets/user/iconfont.css"
import "../src/assets/user/iconfont.js"
import "../src/assets/download/font_sdjk123/iconfont.css"
import "../src/assets/download/font_sdjk123/iconfont.js"
//import VueNativeSock from 'vue-native-websocket'




const 视图应用 = createApp(App);
//const app = createApp(App);
//app.component('font-awesome-icon', FontAwesomeIcon);



for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  视图应用.component(key, component);
}
//let title = ref<string>("title");
//provide("title", title);

视图应用.use(store).use(router).use(ElementPlus, { locale: zhCn }).mount("#app");
//app.config.globalProperties.$websocket.emit('eventName', data)
