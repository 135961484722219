export interface LoginFormInt {
    userid: string
    userpw: string
}
export class LoginData1 {
    ruleForm:LoginFormInt={
        userid:"",
        userpw:""
    }
}


export interface orginfo1 {
    name: string
   
    
    fid:string
  
   
   
}
export class orgData1 {
   list:orginfo1[]=[]
   
}
