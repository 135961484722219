import { createStore, Store } from "vuex";
import { ComponentCustomProperties } from "vue";
declare module "@vue/runtime-core" {
  interface Stata1 {
    editableTabsValue: "Index";
    editableTabs: [
      {
        routerlink: string;
        dataname: string;
      }
    ];
    item: any;
  }
  interface ComponentCustomProperties {
    $store: Store<Stata>;
  }
}
export default createStore({
  state: {
    item: "",

    editableTabsValue: "Index",
    editableTabs: [
      {
        dataname: "",
        routerlink: "",
      },
    ],
  },
  mutations: {
    addTab(state, tab) {
      let index = state.editableTabs.findIndex(
        (e: { routerlink: any }) => e.routerlink === tab.routerlink
      );

      if (index === -1) {
        state.editableTabs.push({
        dataname: tab.dataname,
          routerlink: tab.routerlink,
        });
      }

      state.editableTabsValue = tab.routerlink;
    },
    removeTab(state, ttab: string) {
      state.editableTabs = state.editableTabs.filter(
        (tab) => tab.routerlink !== ttab
      );
    },
    resetState: (state) => {
      state.editableTabsValue = "Index";
      state.editableTabs = [
        {
          dataname: "首页",
          routerlink: "",
        },
      ];
    },
  },
});
