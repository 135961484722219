import axios from "axios";
import { ElMessage } from "element-plus";
import store from "@/store/index";
import router from "@/router"
//store.commit("setimageurl", "http://192.168.11.3:8081/api");
//store.commit("setimageurl", "http://172.28.43.145:8081");
store.commit("setimageurl", "https://www.sdjk123.cn/api");
//store.commit("setimageurl", "http://47.120.49.8:8081");
// 创建axios实例
const service = axios.create({
  //baseURL: "http://192.168.11.3:8081/api",
  // baseURL: "http://47.120.49.8:8081",
  baseURL: "https://www.sdjk123.cn/api",
  //baseURL: "http://47.120.49.8:8081",
  timeout: 15000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    type: "admin",
  },
});
// 请求拦截
service.interceptors.request.use((config) => {
  config.headers = config.headers || {};
  if (localStorage.getItem("token")) {
    config.headers.token = localStorage.getItem("token") || "";
  }
  return config;
});
// 响应拦截
service.interceptors.response.use(
  (res: any) => {
   
    const code: number = res.data.code;

    if (code != 200) {
      if (code == 300) {
        ElMessage(res.data.msg)


        return Promise.reject("res");
      } else {
       
        if (res.config.headers.type === "user")
          router.push("/about");
        else
          router.push("/login");
        return res
      }
      // return res
    }

    return res;
  },
  (res) => {
    ElMessage(res);
  }
);
export default service;
